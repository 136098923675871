<template>
  <div v-if="allNodeList.length">
    <div  class="flow-content" v-for="flowItem in allNodeList">
      <el-timeline class="node-block">
        <el-timeline-item
            placement="bottom"
            v-for="(value,key) in flowItem.activityNodeList" :key="key"
            type="primary"
            :class="[getNodeStatus(value.dealType,value.nodeType),value.busStatus==1?'timeout':'']"
        >
          <div class="timeline-nodeName clearfix">
            <div class="apply-time" v-if="value.nodeType==0" title="申请时间">{{value.dealTime}}</div>
            <span>{{value.nodeName}}</span>
            <span class="blue mgl10">{{value.nodeAuditType!=null?(value.nodeAuditType==1?'>>>会签':'>>>或签'):''}}</span>
          </div>
          <div v-for="user in value.userList" class="timeline-approver">
            <div class="timeline-time">{{getHandleTime(value,user)}}</div>
            <span v-if="user.deptName">{{user.deptName}}</span><br>
            <span v-if="user.name">{{user.name}}{{getHandleStatus(user.dealType,value.nodeType)}}</span>
            <div class="timeline-reason"  v-if="user.dealReson">
              {{user.dealReson}}
            </div>
            <div class="timeline-reason" v-if="user.dealRemark&&Array.isArray(user.dealRemark)">
              <label  class="mgr10">附件:</label>
              <div style="display: flex;flex-wrap: wrap;align-items: center;">
                <div class="files-item" v-for="(imgitr,index) in user.dealRemark" :key="index">
                <div class="file-img">
                  <el-button type="text" @click="handleDownloadClick(imgitr)">下载</el-button>
                  <img :src="imgitr.url" alt="" v-if="['.png','.jpg','.jpeg','gif','GIF','.PNG','.JPG','.JPEG'].includes(imgitr.url.slice(imgitr.url.lastIndexOf('.')))" @click="handlePreviewClick(user.dealRemark)">
                <div @click="showViedo(imgitr)" style="width: 100%;height: 100%;margin-left: 2px;margin-top: 1px;background: #bbb8b8;display: flex;align-items: center;justify-content: center;" v-else-if="['.mp4','.wmv','.m4v','.avi','.ogg','.flv','.rmvb'].includes(imgitr.url.slice(imgitr.url.lastIndexOf('.')))">
                    <img style="margin:0;width: 40px;height: 40px;" src="https://reconova-test.oss-cn-zhangjiakou.aliyuncs.com/AIOS/miniprogram/pic/video.png">
                </div>
                <img :src="getDefaultImg(imgitr.name || imgitr.url)" alt="" @click="getFileImg(imgitr)" v-else>
                <div :title="imgitr.name">{{ imgitr.name }}</div>
                </div>
                <div  class="wordfile" v-if="viedoUrl" @click="closeViedo">
                <div @click.stop="closeViedo" style="position:absolute;top: 6%;right: 7%;font-size: 40px;cursor: pointer;"><i class="el-icon-error"></i></div>
                <div style="width:80%;height:80%;margin-left: 10%;margin-top: 6%;background: #fff;">
                  <video width="100%" height="100%" controls autoplay>
                    <source :src="viedoUrl" type="video/ogg">
                    <source :src="viedoUrl" type="video/mp4">
                    <source :src="viedoUrl" type="video/webm">
                    <object data="movie.mp4" width="100%" height="100%">
                      <embed width="100%" height="100%" :src="viedoUrl">
                    </object>
                  </video>
                </div>
              </div>
              <div class="wordfile" v-if="fileUrl" @click="closeIframe">
                <div @click.stop="closeIframe"
                  style="position:absolute;top: 2%;right: 7%;font-size: 40px;cursor: pointer;"><i
                    class="el-icon-error"></i></div>
                <iframe :src="fileUrl" width='80%' height='90%' frameborder='0'></iframe>
              </div>
              </div>
              </div>
            </div>
            <div class="timeline-resource"  v-if="refuseResource&&refuseResource.length&&(value.nodeType==1&&user.dealType=='1')">
              <label  class="mgr10">附件:</label>
              <template  v-for="(imgitr,index) in refuseResource">
                <viewer v-if="['png','jpg','jpeg'].includes(getFileType(imgitr.name))" :ref="'viewer' + index" style="display: inline-block">
                  <img :src="imgitr.url" alt="" title="单击预览" class="mgr10 resource-item" @click="handlePreview(imgitr,index)">
                </viewer>
                <img v-else-if="['zip','rar','dwg','pdf'].includes(getFileType(imgitr.name))" :src="getImSrc(imgitr.name)" class="mgr10 resource-item" @click="download(imgitr)" title="单击下载">
                <img v-else-if="imgitr.name.includes('.pdf')" src="@/assets/pdf.png" class="mgr10 resource-item" @click="handlePreview(imgitr,index)"  title="单击预览">
              </template>
            </div>
          </div>
        </el-timeline-item>
      </el-timeline>
    </div>
    <el-dialog :visible.sync="showPreview" width="600px">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
    <viewer ref="pdfListViewer" class="preview"  :images="currentViewPhotos">
      <img v-for="(src,index)  in currentViewPhotos" :src="src" :key="index">
    </viewer>
  </div>
  <div v-else>
    <div class="no-flow-data">暂无数据</div>
  </div>

</template>

<script>
import {previewPDF, isJSON} from "../utils/utils";
import { saveAs } from 'file-saver'

export default {
  name: "detail-flow",
  data(){
    return {
      viedoUrl: '',
      fileUrl: '',
      flowDetail:[],
      allNodeList:[],
      showPreview:false,
      dialogImageUrl:'',
      currentViewPhotos:[]
    }
  },
  props:['flows','dataStatus','refuseResource'],
  watch:{
    "flows":function (newValue,oldValue){
      if(newValue){
        this.flowDetail = newValue;
        this.initActivityNode()
      }
    }
  },
  mounted() {
    this.flowDetail = this.flows;
    this.initActivityNode()
  },
  methods:{
    handleDownloadClick(file) {
      if (file.url) {
        saveAs(file.url, file.name)
      }
    },
    closeViedo(){
      this.viedoUrl = ''
    },
    closeIframe(){
      this.fileUrl = ''
    },
    getFileImg(item){
      const fileType = item.url.substr(item.url.lastIndexOf(".")+1)
      if(['pdf'].includes(fileType)){
        this.fileUrl = item.url
      } else if(['doc','docx','xls','xlsx','ppt','pptx'].includes(fileType)){
        this.fileUrl = "https://view.officeapps.live.com/op/view.aspx?src="+encodeURIComponent(item.url)
      } else {
        this.$message.warning("暂不支持预览该类型的文件！")
      }
    },
    showViedo(item){
      this.viedoUrl = item.url
    },
    handlePreviewClick(files) {
      if (files && files.length) {
        let images =  files.filter(item => ['.png','.jpg','.jpeg','gif','GIF','.PNG','.JPG','.JPEG'].includes(item.url.slice(item.url.lastIndexOf('.')))).map(item => item.url)
        this.$viewerApi({
          images
        })
      }
    },
    getDefaultImg(fileName){
      var orign = 'https://reconova-test.oss-cn-zhangjiakou.aliyuncs.com/AIOS/miniprogram/pic/'
      if (fileName) {
        const splitIndex = fileName.lastIndexOf('.');
        const str = fileName.slice(splitIndex-1)
        if(str.indexOf('.pdf')!=-1){
          return orign + 'pdf1.png'
        }else if(str.indexOf('.doc')!=-1 || str.indexOf('.docx')!=-1){
          return orign + 'word.png'
        }else if(str.indexOf('.xls')!=-1 || str.indexOf('.xlsx')!=-1){
          return orign + 'excel.png'
        }else if(str.indexOf('.ppt')!=-1 || str.indexOf('.pptx')!=-1){
          return orign + 'file_ppt.png'
        }else if(str.indexOf('.zip')!=-1){
          return orign + 'zip.png'
        }else if(str.indexOf('.rar')!=-1){
          return orign + 'rar.png'
        }else{
          return orign + 'file-cion.png'
        }
      } else {
        return orign + 'file-cion.png'
      }
    },
    initActivityNode(){
      this.allNodeList = []
      if(this.flowDetail&&this.flowDetail.length){
        for(let i=this.flowDetail.length-1;i>=0;i--){
          this.allNodeList[i]={};
          this.allNodeList[i].activityNodeList = {}
          if (this.flowDetail[i]) {
            for(let item of this.flowDetail[i]){
            const key = '+' + item.nodeName; // 兼容数字key，确保所有属性都按照插入的顺序遍历
            if(this.allNodeList[i].activityNodeList[key]){
              if(item.userList&&item.userList.length){
                // 不管会签或者是或签 只要有人拒绝了  节点状态就是拒绝    会签：所有人都通过了节点才会通过(即后面的user状态需要覆盖前面的user的状态)，或签：有一个人同意了节点就是通过的
                // nodeAuditType ---0---或签   ----1----会签
                let type = null;
                if(item.dealType=='1'){
                  type='1'
                }else if(item.nodeAuditType==1){  // 会签
                  type = [item.dealType,this.allNodeList[i].activityNodeList[key].dealType].some(value=>value=='1')?'1':([item.dealType,this.allNodeList[i].activityNodeList[key].dealType].some(value=>value==null)?null:'0');
                }else if(item.nodeAuditType==0){  // 或签
                  type = item.dealType=='0'?'0':this.allNodeList[i].activityNodeList[key].dealType
                }else{
                  type = this.allNodeList[i].activityNodeList[key].dealType;
                }
                this.allNodeList[i].activityNodeList[key].dealType = type;
                item.userList[0].dealType = item.dealType;
                item.userList[0].dealTime = item.dealTime;
                item.userList[0].dealReson = item.dealReson;
                item.userList[0].dealRemark = isJSON(item.dealRemark) ? JSON.parse(item.dealRemark) : item.dealRemark;
                this.allNodeList[i].activityNodeList[key].userList = this.allNodeList[i].activityNodeList[key].userList.concat(item.userList)
              }
            }else{
              this.allNodeList[i].activityNodeList[key]=JSON.parse(JSON.stringify(item));
              this.allNodeList[i].activityNodeList[key].userList = [];
              if(item.userList){
                if(item.userList.length===1){
                  item.userList[0].dealType = item.dealType
                  item.userList[0].dealTime = item.dealTime;
                  item.userList[0].dealReson = item.dealReson;
                  item.userList[0].dealRemark = isJSON(item.dealRemark) ? JSON.parse(item.dealRemark) : item.dealRemark;
                }else{
                  item.userList = item.userList.map(value=>{
                    value.dealType=item.nodeType==2&&item.dealType==0?'0':null
                    value.dealReson = item.dealReson;
                    value.dealTime = item.dealTime;
                    value.dealRemark = isJSON(item.dealRemark) ? JSON.parse(item.dealRemark) : item.dealRemark;
                    return value
                  })
                }
                this.allNodeList[i].activityNodeList[key].userList  = this.allNodeList[i].activityNodeList[key].userList.concat(item.userList)
              }
              // 审批人为空自动通过 显示理由
              if(item.dealer == 0){
                this.allNodeList[i].activityNodeList[key].userList = [{ dealReson: item.dealReson}]
              }
            }
          }
          }
        }
      }
    },
    getHandleStatus(dealtype,nodetype){
      // nodeType--0申请节点  ---1审批节点  ---2抄送节点
     // dealtype--0通过 ---1拒绝  ---2撤销  ---3终止  ---4提审  ---null未处理
     if(dealtype==2){
        return '(已撤销)'
      }else if(dealtype==3){
        return '(已终止)'
      }else if(dealtype==null||dealtype==4){
        return ''
      }else if(dealtype==1){
        return '(已驳回)'
      }else if(dealtype==0&&nodetype){
        return nodetype==2?'(已抄送)':'(已同意)';
      }
    },

    getNodeStatus(dealType,nodetype){
      // dealtype--0通过 ---1拒绝  ---2撤销  ---3终止  ---4提审  ---null未处理
      // 'approve':dealType=='0'||dealType==4,'refuse':dealType=='1','default':dealType==null||value.dealType==null
      return ['approve','refuse','repeal','termination','approve'][dealType]||'default'
    },

    getHandleTime(item,user){
      if(item.nodeType==0){
        return this.dataStatus==1?item.cancelTime:(this.dataStatus==7?item.endTime:"")
      }else{
        return user.dealTime
      }
    },

    // 预览图片
    handlePreview(file,index){
      if (file.isPicturePreview) {  // 预览pdf
        previewPDF(file).then(res=>{
          this.currentViewPhotos = res;
          this.$refs.pdfListViewer.$viewer.show()
        })
      } else {
        // this.showPreview = true;
        // this.dialogImageUrl = file.url
        this.$refs["viewer" + index][0].$viewer.show()
      }
    },
    download(file) {
      window.location.href = file.url;
    },
    getFileType(filename){
      return filename.slice(filename.lastIndexOf('.')+1)
    },

    getImSrc(filename){
      const type = this.getFileType(filename);
      return ['zip','rar','pdf'].includes(type)?require(`@/assets/${type}.png`):require(`@/assets/file_default.png`)
    }

  }
}
</script>

<style scoped lang="scss">
  .wordfile{
    position: fixed;
    left: 0;
    right:0;
    top:0;
    bottom: 0;
    z-index:99;
    background-color: rgba(0, 0, 0, 0.2);
    iframe{
      margin-left: 10%;
      margin-top: 3%;
    }
  }
  .files-item{
    margin-left: 10px;
    margin-top: 5px;
    position: relative;
  }
  .file-img{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .file-img img{
    width: 45px;
    height: 45px;
  }
  .file-img div{
    max-width: 73px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
.flow-content{
  margin: 0 20px 0 60px;
  width: 664px;
  padding-top: 20px;
  &+.flow-content{
    border-top: 1px dashed #adafb3;
  }
  .node-block{
    display: inline-block;
    margin-left: 15px;
    vertical-align: text-top;
    padding: 5px 0;

    .timeline-nodeName{
      margin-bottom: 10px;
      position: relative;
      overflow: hidden;
      .apply-time{
        color: #888888;
        display: inline-block;
        float: right;
        cursor:pointer;
      }
    }
    .timeline-approver{
      &+.timeline-approver{
        margin-top: 10px;
        padding-top: 10px;
        border-top: 1px solid #dedede;
      }
      span{
        line-height: 20px;
        display: inline-block;
      }
      span:nth-of-type(2){
        color:#888888
      }
      .timeline-time{
        color: #888888;
        display: inline-block;
        float: right;
        margin-top: 15px;
      }
      .timeline-reason{
        background: #f5f5f5;
        border-radius: 5px;
        line-height: 2;
        padding: 5px;
        margin: 5px -5px;
      }
      .resource-item{
        width: 42px;
        height: 42px;
        vertical-align: middle;
        cursor: pointer;
      }
    }
  }
}
.no-flow-data{
  padding:16px 20px 0 20px;
}

::v-deep.node-block .el-timeline-item__content{
  width: 580px;
}
::v-deep .refuse .el-timeline-item__node{
  background: $rejectColor;
}
::v-deep .default .el-timeline-item__node{
  background: #e3e3e3;
}
::v-deep .repeal .el-timeline-item__node{
  background: $repealColor;
}
::v-deep .termination .el-timeline-item__node{
  background: $terminationColor;
}
.preview{
  display: none;
}
::v-deep.timeout .el-timeline-item__content,.timeout .timeline-approver span{
  color: red !important;
}
</style>